<template>
	<div></div>
</template>
<script>

import { getUserInfo } from '@/request/api/user';
export default {
	mounted () {
		this.getParam();
	},
	methods: {
		getUser () {
			getUserInfo().then(data => {
				if (data && data === 'retry') {
					this.getUser();
				} else if (data) {
					this.getImUserSig();
					window.sessionStorage.setItem('userInfo', JSON.stringify(data));
					const backUrl = window.localStorage.getItem('backUrl');
					if (!data.hasDefaultPatient) {
						if (!window.localStorage.getItem('backUrl')) {
							window.localStorage.setItem('backUrl', window.location.pathname + window.location.search);
						}
						this.$root.replace('/add-patient-default');
					} else {
						window.localStorage.setItem('backUrl', '');
						if (backUrl && backUrl.split('?')[0] === '/wo') {
							this.$root.replace('/');
						} else if (backUrl) {
							this.$root.replace(backUrl);
						} else {
							this.$root.go('/');
						}
					}
				}
			});
		},
		getParam () {
			const param = this.$route.query;
			if (param.accessToken) {
				window.sessionStorage.setItem('loginInfo', JSON.stringify(param));
				this.getUser();
			} else if (param.openid) {
				this.$root.go('/login', { openid: param.openid });
			} else {
				this.$toast('network error');
			}
		}
	}
};
</script>